import React from "react";
import { Router } from "@reach/router";
import Leaderboard from "./components/Leaderboard";
import Admin from "./components/Admin";
import "semantic-ui-css/semantic.min.css";

function App() {
  const adminView = true;
  return (
    <Router>
      <Leaderboard path="/" adminView={adminView} />
      <Admin path="/admin" adminView={adminView} />
    </Router>
  );
}

export default App;
