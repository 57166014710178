import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import Row from "./Row";
import { useConsultants } from "../Admin/useConsultants";
import Background from "../../assets/background.png";

const Leaderboard = ({ adminView }) => {
  const [visibleItems, setVisibleItems] = React.useState([]);
  const [consultants] = useConsultants(
    adminView ? "account-managers" : "sales"
  );
  const [step, setStep] = React.useState(1);
  const [totalSteps, setTotalSteps] = React.useState();
  const [isPortrait, setIsPortrait] = React.useState(true);

  React.useEffect(() => {
    setIsPortrait(window.innerWidth < window.innerHeight);
  }, []);

  const doStuff = () => {
    const items = consultants.slice((step - 1) * 10, step * 10);
    setVisibleItems(items);
    setStep(step === totalSteps ? 1 : step + 1);
  };

  React.useEffect(() => {
    if (consultants.length > 10) {
      const interval = setInterval(doStuff, 6000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [consultants, visibleItems]);

  React.useEffect(() => {
    if (consultants.length < 10) {
      setAnimateIn(!animateIn);
      setVisibleItems(consultants);
    }
  }, [consultants]);

  React.useEffect(() => {
    setTotalSteps(Math.ceil(consultants.length / 10));
  }, [consultants]);

  const [animateIn, setAnimateIn] = React.useState(false);
  React.useEffect(() => {
    if (consultants.length > 10) {
      if (animateIn) {
        setAnimateIn(!animateIn);
      } else {
        setTimeout(() => {
          setAnimateIn(!animateIn);
        }, 3000);
      }
    }
  }, [visibleItems]);

  return (
    <>
      {/* {isPortrait ? (
        <PortraitView
          adminView={adminView}
          visibleItems={visibleItems}
          animateIn={animateIn}
        />
        ) : (
          <LandscapeView
          adminView={adminView}
          visibleItems={visibleItems}
          animateIn={animateIn}
          />
        )} */}
      <PortraitView
        adminView={adminView}
        visibleItems={visibleItems}
        animateIn={animateIn}
        consultants={consultants}
      />
    </>
  );
};

const LandscapeView = ({ adminView, visibleItems, animateIn }) => "land";

const PortraitView = ({ adminView, visibleItems, animateIn, consultants }) => (
  <>
    <Header
      title={!adminView ? "SALES" : "ACCOUNT"}
      subTitle={!adminView ? "LEADERBOARD" : "MANAGERS"}
      adminView={adminView}
    />
    <Wrap className="App">
      <AdminOverlay adminView={adminView}>
        {consultants && consultants.length <= 10
          ? consultants.map((item, i) => (
              <Row
                name={item.name}
                score={item.budget}
                rank={item.rank}
                index={i}
                teamName={item.teamName}
                visible={false}
                key={item.id}
                animateIn={false}
                adminView={adminView}
              />
            ))
          : null}
        {visibleItems.length > 0 && consultants.length > 10
          ? visibleItems.map((item, i) => (
              <Row
                name={item.name}
                score={item.budget}
                rank={item.rank}
                index={i}
                teamName={item.teamName}
                visible={false}
                key={item.id}
                animateIn={animateIn}
                adminView={adminView}
              />
            ))
          : null}
      </AdminOverlay>
    </Wrap>
    <Footer adminView={adminView} />
  </>
);

export default Leaderboard;

const Wrap = styled.div`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  background: url(${Background});
`;

const AdminOverlay = styled.div`
  ${({ adminView }) =>
    adminView
      ? `background: #272f73cc;
        min-height: 80vh;`
      : ""}
`;
