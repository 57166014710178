import React from "react";

const Header = ({ title = "SALES", subTitle = "LEADERBOARD", adminView }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1234.47 200.35"
    >
      <defs>
        <radialGradient
          id="eb01dfb1-3dda-426e-8910-d31cb581fdb3"
          cx="21.41"
          cy="87.03"
          fx="-8.918255713523266"
          r="368"
          gradientTransform="translate(0 -5.4) scale(1 1.06)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#00a6e1" />
          <stop offset="0.91" stopColor="#273284" />
        </radialGradient>
        <linearGradient
          id="b187bc50-fd7d-49fe-b9be-453e2b5bdaf7"
          x1="233.74"
          y1="180.14"
          x2="692.24"
          y2="180.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.23" stopColor="#2aace2" stopOpacity="0.9" />
          <stop offset="0.85" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
      <title>header</title>
      <g id="bf1cced0-15d0-4586-bf38-46b1f56ef59d" data-name="Layer 1">
        <polygon
          points="1.92 -0.35 472.91 1.26 364.44 179.65 1.92 179.65 1.92 -0.35"
          fill="url(#eb01dfb1-3dda-426e-8910-d31cb581fdb3)"
        />
        <g id="abb4c0ed-5fa0-4b50-912c-39a8174f701c" data-name="title">
          <text
            transform="translate(680.96 69.39)"
            fontSize="59.85"
            fill="#2c3582"
            fontFamily="Arial"
            fontWeight="700"
            letterSpacing="0.05em"
          >
            {title}
            <tspan letterSpacing="0.05em">
              <tspan x={adminView ? "0" : "-113.15"} y="58.92">
                {subTitle}
              </tspan>
            </tspan>
          </text>
        </g>
        <g
          id="b9dc1d2f-f049-4c49-8081-a74b129071f1"
          data-name="Zimmer Biomet long white"
        >
          <g
            id="a8feecbb-671a-4377-9f58-9a77b9a8fe1d"
            data-name="Zimmer Biomet long white"
          >
            <path
              d="M112,99.55l13.12-20.42h-12v-2.3H129v.42L116.24,97.6h12.57V100H112Zm21.45-22.72h2.82V100h-2.82Zm8.81,0h3.92l7.16,18,6.94-18h3.89V100h-2.82V80.49L153.67,100h-.88l-7.94-19.51V100h-2.59Zm27.74,0h3.92l7.16,18,6.94-18h3.89V100h-2.82V80.49L181.41,100h-.88l-7.94-19.51V100H170Zm27.74,0h13.51v2.3H200.56V87h8.62v2.31h-8.62V97.7h11.27V100H197.74Zm18.34,0h7c4.44,0,8.1,1.81,8.1,6.45v.13c0,3.82-2.56,5.7-5.74,6.32l7.1,10.27h-3.15l-6.9-10.08H218.9V100h-2.82Zm7.16,10.85c3.31,0,5.09-1.42,5.09-4.27v-.13c0-3.15-2-4.18-5.09-4.18H218.9v8.58ZM245,76.83h7c4.31,0,7.46,1.42,7.46,6V83a4.75,4.75,0,0,1-3.5,4.9c3.37.81,4.63,2.52,4.63,5.54v.13c0,4.37-3,6.48-7.62,6.48H245Zm7,10.11c3.41,0,4.7-1.23,4.7-4.08v-.13c0-2.63-1.52-3.7-4.76-3.7h-4.15v7.91Zm.91,10.86c3.37,0,4.93-1.49,4.93-4.31v-.13c0-2.86-1.59-4.22-5.32-4.22h-4.73V97.8Zm12.44-21h2.82V100h-2.82ZM273,88.56V88.3c0-6.58,4.6-11.76,11.41-11.76s11.31,5.18,11.31,11.7v.26c0,6.54-4.51,11.79-11.25,11.79S273,95,273,88.56m19.8-.1V88.2c0-5.18-3-9.4-8.39-9.4s-8.49,4-8.49,9.47v.26c0,5.6,3.73,9.43,8.55,9.43,5.16,0,8.33-3.89,8.33-9.5m7.62-11.63h3.92l7.16,18,6.93-18h3.89V100h-2.82V80.49L311.83,100H311L303,80.49V100h-2.59Zm27.73,0h13.52v2.3H331V87h8.62v2.31H331V97.7h11.28V100h-14.1Zm23.11,2.3h-6.74v-2.3h16.3v2.3h-6.74V100h-2.82Z"
              fill="#fff"
            />
            <path
              d="M72.44,61.1A28.55,28.55,0,1,1,43.89,89.65c0-17,11.52-28.55,28.55-28.55M53.09,108,72.37,71.34l-.19,0A18.25,18.25,0,0,0,54,88V70.55H91A26.54,26.54,0,0,0,72.44,63c-16,0-26.64,10.68-26.64,26.64A26.56,26.56,0,0,0,53.09,108m.47.47A26.64,26.64,0,0,0,91.48,71l-19.1,36.66A18.19,18.19,0,0,0,90.19,92v16.47Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <polygon
              points="117.65 122.37 113.1 114.7 114.97 114.7 118.53 121.02 121.95 114.7 123.6 114.7 119.31 122.39 119.31 128.3 117.65 128.3 117.65 122.37"
              fill="#fff"
            />
            <path
              d="M123.57,123.41v-.15a4.89,4.89,0,1,1,9.77,0v.15a4.89,4.89,0,1,1-9.77,0m8.14,0v-.13c0-2.3-1.28-3.78-3.25-3.78s-3.26,1.48-3.26,3.76v.15c0,2.27,1.24,3.75,3.26,3.75s3.25-1.5,3.25-3.75"
              fill="#fff"
            />
            <path
              d="M135.71,124.65v-6.3h1.57v6.22c0,1.76.75,2.53,2.3,2.53a2.62,2.62,0,0,0,2.74-2.72v-6h1.58v10h-1.58v-1.57a3.4,3.4,0,0,1-3.15,1.74c-2,0-3.46-1.08-3.46-3.82"
              fill="#fff"
            />
            <path
              d="M147.1,118.35h1.58v1.79a3.48,3.48,0,0,1,3.27-2v1.49c-2,.09-3.27.72-3.27,3.17v5.46H147.1Z"
              fill="#fff"
            />
            <path
              d="M159.3,118.35h1.58V120a4.08,4.08,0,0,1,3.36-1.85c2.61,0,4.53,1.91,4.53,5v.15c0,3-1.79,5.13-4.53,5.13a3.75,3.75,0,0,1-3.36-1.8v5.09H159.3Zm7.83,5.06v-.15c0-2.51-1.39-3.76-3.06-3.76-1.84,0-3.25,1.21-3.25,3.76v.15c0,2.55,1.33,3.75,3.27,3.75s3-1.33,3-3.75"
              fill="#fff"
            />
            <path
              d="M171.22,118.35h1.58v1.79a3.49,3.49,0,0,1,3.27-2v1.49c-2,.09-3.27.72-3.27,3.17v5.46h-1.58Z"
              fill="#fff"
            />
            <path
              d="M177.35,123.41v-.15a4.89,4.89,0,1,1,9.78,0v.15a4.89,4.89,0,1,1-9.78,0m8.14,0v-.13c0-2.3-1.27-3.78-3.25-3.78S179,121,179,123.26v.15c0,2.27,1.23,3.75,3.25,3.75s3.25-1.5,3.25-3.75"
              fill="#fff"
            />
            <path
              d="M189.05,128.87h1.62c.23,1.14,1.18,1.73,2.87,1.73,2,0,3.19-.93,3.19-3.06V126.1a4.11,4.11,0,0,1-3.36,1.88,4.48,4.48,0,0,1-4.53-4.72v-.13a4.71,4.71,0,0,1,4.64-4.95,3.55,3.55,0,0,1,3.25,1.77v-1.6h1.58v9.29c0,2.89-2,4.29-4.75,4.29-2.95,0-4.24-1.38-4.51-3.06m7.74-5.72V123c0-2.22-1.22-3.51-3.16-3.51s-3.15,1.46-3.15,3.55v.15c0,2.17,1.39,3.47,3.06,3.47a3.21,3.21,0,0,0,3.25-3.52"
              fill="#fff"
            />
            <path
              d="M201.51,118.35h1.58v1.79a3.49,3.49,0,0,1,3.27-2v1.49c-2,.09-3.27.72-3.27,3.17v5.46h-1.58Z"
              fill="#fff"
            />
            <path
              d="M207.64,123.43v-.15c0-3,1.92-5.1,4.66-5.1,2.3,0,4.47,1.39,4.47,4.95v.51h-7.5c.08,2.27,1.16,3.52,3.2,3.52,1.54,0,2.43-.57,2.64-1.73h1.58c-.34,2-2,3-4.24,3a4.67,4.67,0,0,1-4.81-5m7.51-1.06c-.15-2.06-1.22-2.87-2.85-2.87a3,3,0,0,0-3,2.87Z"
              fill="#fff"
            />
            <path
              d="M218.46,125.24H220c.12,1.18.75,1.92,2.34,1.92s2.17-.51,2.17-1.58-.78-1.39-2.32-1.67c-2.59-.48-3.46-1.2-3.46-2.93s1.81-2.8,3.37-2.8c1.78,0,3.32.73,3.59,2.87h-1.52a1.81,1.81,0,0,0-2.09-1.56c-1.14,0-1.89.58-1.89,1.43s.5,1.26,2.32,1.58c2.06.38,3.5.84,3.5,3,0,1.8-1.23,3-3.65,3s-3.8-1.2-3.9-3.23"
              fill="#fff"
            />
            <path
              d="M227.59,125.24h1.54c.12,1.18.75,1.92,2.34,1.92s2.17-.51,2.17-1.58-.78-1.39-2.32-1.67c-2.58-.48-3.46-1.2-3.46-2.93s1.81-2.8,3.37-2.8c1.79,0,3.33.73,3.59,2.87H233.3a1.81,1.81,0,0,0-2.09-1.56c-1.14,0-1.89.58-1.89,1.43s.5,1.26,2.32,1.58c2.06.38,3.51.84,3.51,3,0,1.8-1.24,3-3.66,3s-3.8-1.2-3.9-3.23"
              fill="#fff"
            />
            <path
              d="M237.41,127.18a1.22,1.22,0,1,1,1.22,1.22,1.21,1.21,0,0,1-1.22-1.22"
              fill="#fff"
            />
            <path
              d="M358,127.18a1.22,1.22,0,0,1,2.44,0,1.22,1.22,0,1,1-2.44,0"
              fill="#fff"
            />
            <path
              d="M246.74,121.59v-.16a6.67,6.67,0,1,1,13.34,0v.15c0,3.84-2.64,6.92-6.6,6.92a6.63,6.63,0,0,1-6.74-6.88m11.63-.06v-.15c0-3-1.79-5.52-4.93-5.52s-5,2.32-5,5.56v.15c0,3.29,2.18,5.53,5,5.53,3,0,4.89-2.28,4.89-5.57"
              fill="#fff"
            />
            <path
              d="M262.63,124.65v-6.3h1.58v6.22c0,1.76.74,2.53,2.3,2.53a2.62,2.62,0,0,0,2.74-2.72v-6h1.58v10h-1.58v-1.57a3.42,3.42,0,0,1-3.16,1.74c-2,0-3.46-1.08-3.46-3.82"
              fill="#fff"
            />
            <path
              d="M274,118.35h1.57v1.79a3.49,3.49,0,0,1,3.28-2v1.49c-2,.09-3.28.72-3.28,3.17v5.46H274Z"
              fill="#fff"
            />
            <path
              d="M286.22,118.35h1.58V120a4.09,4.09,0,0,1,3.37-1.85c2.6,0,4.53,1.91,4.53,5v.15c0,3-1.79,5.13-4.53,5.13a3.76,3.76,0,0,1-3.37-1.8v5.09h-1.58Zm7.84,5.06v-.15c0-2.51-1.39-3.76-3.07-3.76-1.84,0-3.24,1.21-3.24,3.76v.15c0,2.55,1.32,3.75,3.26,3.75s3.05-1.33,3.05-3.75"
              fill="#fff"
            />
            <path
              d="M298.15,118.35h1.58v1.79a3.48,3.48,0,0,1,3.27-2v1.49c-2,.09-3.27.72-3.27,3.17v5.46h-1.58Z"
              fill="#fff"
            />
            <path
              d="M304.28,123.41v-.15a4.89,4.89,0,1,1,9.77,0v.15a4.89,4.89,0,1,1-9.77,0m8.14,0v-.13c0-2.3-1.28-3.78-3.25-3.78s-3.26,1.48-3.26,3.76v.15c0,2.27,1.24,3.75,3.26,3.75s3.25-1.5,3.25-3.75"
              fill="#fff"
            />
            <path
              d="M316.51,118.35h1.58v1.53a3.22,3.22,0,0,1,3-1.7,2.89,2.89,0,0,1,2.91,1.87,3.84,3.84,0,0,1,3.4-1.87c1.79,0,3.35,1.07,3.35,4v6.16h-1.58V122c0-1.75-.74-2.47-2.11-2.47a2.54,2.54,0,0,0-2.62,2.66v6.09h-1.58V122c0-1.75-.75-2.47-2.12-2.47a2.54,2.54,0,0,0-2.62,2.66v6.09h-1.58Z"
              fill="#fff"
            />
            <path
              d="M333.57,115.23a1.05,1.05,0,1,1,1.05,1,1.05,1.05,0,0,1-1.05-1m.29,3.12h1.58v10h-1.58Z"
              fill="#fff"
            />
            <path
              d="M337.86,125.24h1.54c.11,1.18.74,1.92,2.33,1.92s2.17-.51,2.17-1.58-.78-1.39-2.32-1.67c-2.58-.48-3.46-1.2-3.46-2.93s1.81-2.8,3.37-2.8c1.79,0,3.33.73,3.59,2.87h-1.52a1.81,1.81,0,0,0-2.09-1.56c-1.14,0-1.88.58-1.88,1.43s.49,1.26,2.32,1.58c2,.38,3.5.84,3.5,3,0,1.8-1.24,3-3.66,3s-3.8-1.2-3.89-3.23"
              fill="#fff"
            />
            <path
              d="M347.06,123.43v-.15c0-3,1.93-5.1,4.67-5.1,2.29,0,4.46,1.39,4.46,4.95v.51H348.7c.08,2.27,1.16,3.52,3.2,3.52,1.54,0,2.43-.57,2.64-1.73h1.58c-.35,2-2,3-4.24,3a4.68,4.68,0,0,1-4.82-5m7.52-1.06c-.16-2.06-1.22-2.87-2.85-2.87a3,3,0,0,0-3,2.87Z"
              fill="#fff"
            />
            <polygon
              points="358.12 115.4 358.12 118.23 357.65 118.23 357.65 115.4 356.69 115.4 356.69 115.03 359.08 115.03 359.08 115.4 358.12 115.4"
              fill="#fff"
            />
            <polygon
              points="362.32 118.23 362.26 115.59 361.31 118.23 360.91 118.23 359.97 115.59 359.9 118.23 359.46 118.23 359.58 115.03 360.2 115.03 361.13 117.65 362.05 115.03 362.68 115.03 362.79 118.23 362.32 118.23"
              fill="#fff"
            />
          </g>
        </g>
        <polygon
          points="206.42 158.67 710.75 159.25 710.75 201.6 183.99 201.6 206.42 158.67"
          fill="url(#b187bc50-fd7d-49fe-b9be-453e2b5bdaf7)"
        />
      </g>
    </svg>
  );
};

export default Header;
